<template>
    <div :options="options">
        <Contact class="section fade-in" :isModalOpen="true" :isPage="true" :translate="translate" :language="language"/>
    </div>
</template>
<script>
export default {
    data(){
        return {
            language : ( this.$cookies.get('language') == null  ? 'english'  : this.$cookies.get('language') ),
            options: {
                afterLoad: this.afterLoad,
                scrollBar: false,
                responsiveSlides: true,
                licenseKey: '',
            },
            translate : {
                spanish: {
                    title : 'QUIERES <span class="highlight">SABER MÁS</span>DE NOSOTROS',
                    placeholderFirstName: 'Nombre',
                    placeHolderLastName: 'Apellido',
                    placeHolderEmail: 'E-mail',
                    placeHolderHow: '¿Cómo te podemos ayudar?',
                    button : 'ENVIAR'
                },
                english: {
                    title : 'GET<span class="highlight">IN TOUCH</span>WITH US',
                    placeholderFirstName: 'Firstname',
                    placeHolderLastName: 'Lastname',
                    placeHolderEmail: 'Email Address',
                    placeHolderHow: 'How can we help?',
                    button : 'Submit'
                }
            }
        }
    },
    methods:{
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    },
    
}
</script>
